/* eslint-disable guard-for-in */
/* eslint-disable max-len */
import {
  sprintf,
} from 'sprintf-js'

import {
  MENU_SECTION_OFFSET,
} from '../components/combine/ProductList'
import {
  Client,
  MasterData,
  Menu,
} from '../services/core.type'
import {
  ClientStatus,
} from '../types/client'
import {
  Order,
  OrderModifierOption,
  OrderRef,
} from '../types/order'
import {
  Product,
  ProductDiscount,
  ProductDiscountCondition,
  ProductModifier,
  ProductPrice,
  ProductPriceAttribute,
  ProductTax,
} from '../types/product'
import {
  FIREBASE_ACCESS_HISTORY_REF,
  FIREBASE_CALL_REF,
  FIREBASE_CLIENT_REF,
  FIREBASE_NOTIFY_REF,
  FIREBASE_ORDER_REF,
  FIREBASE_QUEUE_REF,
  FIREBASE_SKU_REF,
  FIREBASE_STOCK_REF,
} from './enums'

export const getLocalToken = () => {
  return localStorage.getItem('access_token')?.toString()
}

export const setLocalToken = (accessToken: string) => {
  localStorage.setItem('access_token', accessToken)
}

export const getLocalClient = () : Client | null => {
  const value = localStorage.getItem('client')
  return value ? JSON.parse(value) : null
}

export const setLocalClient = (client: Client) => {
  localStorage.setItem('client', JSON.stringify(client))
}

export const getLocalClientStatus = () => {
  const value = localStorage.getItem('client_status') as ClientStatus | null
  return value ? value : null
}

export const setLocalClientStatus = (clientStatus: ClientStatus) => {
  localStorage.setItem('client_status', clientStatus)
}

export const clearLocalStoreage = () => {
  localStorage.clear()
}

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getClientFullPath = (shopId: string, branchId: string, psId: string, clientId: string) => {
  return `${sprintf(FIREBASE_CLIENT_REF, shopId, branchId, psId)}/client_id-${clientId}`
}

export const getOrderFullPath = (shopId: string, branchId: string, psId: string, queueNumber: string) => {
  return `${sprintf(FIREBASE_ORDER_REF, shopId, branchId, psId)}/queue_number-${queueNumber}`
}

export const getNotifyFullPath = (shopId: string, branchId: string, psId: string, queueNumber: string) => {
  return `${sprintf(FIREBASE_NOTIFY_REF, shopId, branchId, psId)}/queue_number-${queueNumber}`
}

export const getCallFullPath = (shopId: string, branchId: string, psId: string, queueNumber: string) => {
  return `${sprintf(FIREBASE_CALL_REF, shopId, branchId, psId)}/queue_number-${queueNumber}`
}

export const getQueuePath = (shopId: string, branchId: string, psId: string) => {
  return `${sprintf(FIREBASE_QUEUE_REF, shopId, branchId, psId)}`
}

export const getQueueFullPath = (shopId: string, branchId: string, psId: string, queueNumber: string) => {
  return `${sprintf(FIREBASE_QUEUE_REF, shopId, branchId, psId)}/queue_number-${queueNumber}`
}

export const getAccHistoryFullPath = (userId: string) => {
  return `${sprintf(FIREBASE_ACCESS_HISTORY_REF, userId)}/user_id-${userId}`
}

export const getSkuPath = (shopId: string) => {
  return `${sprintf(FIREBASE_SKU_REF, shopId)}`
}

export const getStockPath = (shopId: string) => {
  return `${sprintf(FIREBASE_STOCK_REF, shopId)}`
}

export const getQueryUrl = (pathname: string) => {
  try {
    const arr = pathname.split('/')
    const qrcodeRefId = arr[2]
    const dateCode = arr[3]

    return {
      qrcodeRefId,
      dateCode,
    }
  } catch (error) {
    return {
      qrcodeRefId: '',
      dateCode: '',
    }
  }
}

export const getProductFullName = (productName: string, attributes: ProductPriceAttribute[]) => {
  let fullname = ''
  let option = ''
  for (let i = 0; i < attributes.length; i++) {
    if (i == 0) {
      option += '('
    }
    option += attributes[i].option
    if (i < attributes.length - 1) {
      option += ', '
    } else {
      option += ')'
    }
  }
  if (attributes.length > 0) {
    fullname = productName + ' ' + option
  } else {
    fullname = productName
  }
  return fullname
}

// convert to base 64 for IonIcon component
export const faIcon = (
    faIcon: {
      icon: [
        number, // width
        number, // height
        string[], // ligatures
        string, // unicode
        string | string[] // svgPathData
      ]
    },
) => {
  return `
    data:image/svg+xml;
    utf8,
    <svg xmlns='http://www.w3.org/2000/svg'
      class='ionicon'
      viewBox='0 0 ${faIcon.icon[0]} ${faIcon.icon[1]}'>
      <path d='${faIcon.icon[4]}'/>
    </svg>
  `
}

export const getCategoryOffsetY = () => {
  const headerElement = document.querySelector('ion-header')
  const headerElementHeight = headerElement?.getBoundingClientRect().height || 0
  return headerElementHeight + MENU_SECTION_OFFSET
}

export const getPriceRangeText = (priceNumbers: number[]) => {
  const min = Math.min(...priceNumbers)
  const max = Math.max(...priceNumbers)
  if (min === max) {
    return min
  } else {
    return `${min} - ${max}`
  }
}

export const getPrice = (price: number, discountPercent: number, discountNumber: number) => {
  const discount = (price - price * ((100 - discountPercent) / 100)) + discountNumber
  return price - discount
}

export const getPriceText = (prices: ProductPrice[], discounts: ProductDiscount[]) => {
  const priceNumbers = prices.map((sku) => sku.price)

  if (discounts.length) {
    const sumPercentage = discounts.reduce((acc, cur) => {
      if (cur.valueType === 'percentage') {
        acc += cur.valueAmount
      }
      return acc
    }, 0)
    const sumNumber = discounts.reduce((acc, cur) => {
      if (cur.valueType === 'number') {
        acc += cur.valueAmount
      }
      return acc
    }, 0)
    if (prices.length === 1) {
      return getPrice(prices[0].price, sumPercentage, sumNumber)
    } else {
      const minPrice = getPrice(Math.min(...priceNumbers), sumPercentage, sumNumber)
      const maxPrice = getPrice(Math.max(...priceNumbers), sumPercentage, sumNumber)
      return getPriceRangeText([minPrice, maxPrice])
    }
  } else {
    return prices.length === 1 ? prices[0].price : getPriceRangeText(priceNumbers)
  }
}

export const sortedMenus = (menus: Menu[]) => {
  return menus.reduce((acc: Menu[], cur) => {
    const menu = cur
    const hasImageProducts = menu.products.filter((product) => product.images.original != null)
    const hasNotImageProducts = menu.products.filter((product) => product.images.original == null)
    acc.push({
      ...menu,
      products: [
        ...hasImageProducts,
        ...hasNotImageProducts,
      ],
    })
    return acc
  }, [])
}

export const isExist = (data: string[], value: string) => {
  return data.includes(value)
}

export const getDiscountText = (valueType: ProductDiscount['valueType'], valueAmount: ProductDiscount['valueAmount']) => {
  let str = valueAmount.toString()
  if (valueType === 'percentage') {
    str += '%'
  }
  return str
}

export const getDiscountConditionalText = (discount: ProductDiscount, discountCondition: ProductDiscountCondition) => {
  const disCondi = discountCondition
  const patt = {
    '>=': `ซื้อมากกว่าหรือเท่ากับ ${disCondi.quantity} ได้รับส่วนลด ${getDiscountText(disCondi.valueType, disCondi.valueAmount)}`,
    '<=': `ซื้อน้อยกว่าหรือเท่ากับ ${disCondi.quantity} ได้รับส่วนลด ${getDiscountText(disCondi.valueType, disCondi.valueAmount)}`,
    '=': `ซื้อเท่ากับ ${disCondi.quantity} ได้รับส่วนลด ${getDiscountText(disCondi.valueType, disCondi.valueAmount)}`,
  }

  return patt[disCondi.condition]
}

export const getProductById = (productId: string, menus: Menu[]) : Product | null => {
  for (let i = 0; i < menus.length; i++) {
    for (let j = 0; j < menus[i].products.length; j++) {
      if (menus[i].products[j].productId === productId) {
        return menus[i].products[j]
      }
    }
  }
  return null
}

export const getModifierOptionNameById = (optionId: string, modifiers: MasterData['modifiers']) : string | null => {
  for (let i = 0; i < modifiers.length; i++) {
    for (let j = 0; j < modifiers[i].options.length; j++) {
      if (modifiers[i].options[j].id === optionId) {
        return modifiers[i].options[j].name
      }
    }
  }
  return null
}

type ProductTaxWithTotal = Omit<ProductTax, 'isDisplayFeeResult' | 'isForSale' | 'isForPurchase' | 'isActive' | 'fee'> & {
  taxTotal: number
  serviceChargeTotal: number
  feeTotal: number
}

const addTax = (taxes: ProductTaxWithTotal[], tax: ProductTax, taxTotal: number, serviceChargeTotal: number, feeTotal: number) => {
  const foundIndex = taxes.findIndex((taxItem) => taxItem.name === tax.name)
  if (foundIndex === -1) {
    taxes.push({
      taxId: tax.taxId,
      formType: tax.formType,
      groupName: tax.groupName,
      name: tax.name,
      type: tax.type,
      amount: tax.amount,
      taxTotal: taxTotal,
      serviceChargeTotal: serviceChargeTotal,
      feeTotal: feeTotal,
    })
  } else {
    if (tax.type === 'exclude') {
      taxes[foundIndex].taxTotal += taxTotal
      taxes[foundIndex].serviceChargeTotal += serviceChargeTotal
      taxes[foundIndex].feeTotal += feeTotal
    } else {
      taxes[foundIndex].taxTotal -= taxTotal
      taxes[foundIndex].serviceChargeTotal -= serviceChargeTotal
      taxes[foundIndex].feeTotal -= feeTotal
    }
  }
  return taxes
}

const calcTax = (price: number, tax: ProductTax, groupTaxes: any[]) => {
  let formula
  if (tax.type == 'include') {
    formula = taxIncFn
  } else if (tax.type == 'exclude') {
    formula = taxExcFn
  }
  return calcTaxWithFee(price, tax, formula, groupTaxes)
}

const taxIncFn = (price: number, taxRate: number) => {
  return price - (price * 100 / (100 + taxRate))
}

const taxExcFn = (price: number, taxRate: number) => {
  return price * taxRate / 100
}

const calcTaxWithFee = (price: number, tax: ProductTax, formula: any, groupTaxes: any[]) => {
  let taxTotal = 0
  let serviceChargeTotal = 0
  let feeTotal = 0
  if (tax.formType === 'tax') {
    taxTotal = formula(price, tax.amount)
  } else if (tax.formType === 'service_charge') {
    serviceChargeTotal = formula(price, tax.amount)
  } else if (tax.formType === 'fee') {
    if (tax.fee?.calcType === 'net') {
      feeTotal = formula(price, tax.amount)
    } else if (tax.fee?.calcType === 'custom') {
      const refTaxId = tax.fee.calcTaxId
      const refTax = groupTaxes.find((taxItem) => taxItem.id === refTaxId)
      if (refTax) {
        feeTotal = formula(refTax.feeTotal, tax.amount)
      }
    }
  }
  return {
    taxTotal,
    serviceChargeTotal,
    feeTotal,
  }
}

type TaxWithCalc = ProductTax & {
  result: {
    exclusive: number | null
    inclusive: number | null
    taxTotal: number
    serviceChargeTotal: number
    feeTotal: number
  }
  total: number
  displayText: string
}

export const roundNumber = (n: number, masterData: MasterData) => {
  if (!masterData.posSettings.formatNumber.isRound) {
    return n
  }
  return Math.round(n)
}

export const getOrderTotal = (orders: Order[], masterData: MasterData, includeDiscount = true, includeTax = true) => {
  const round = (number: number) => roundNumber(number, masterData)

  function getResultPrice(order: Order) {
    let result = order.price
    for (const m in order.modifiers) {
      for (const n in order.modifiers[m].options) {
        const optionPrice = order.modifiers[m].options[n].price
        result += optionPrice
      }
    }
    return result
  }

  let subTotalNumber = 0
  let discountNumber = 0
  let vatExcNumber = 0
  let vatIncNumber = 0 // vat inclusive
  let whtNumber = 0
  let feeNumber = 0
  let serviceChargeNumber = 0
  let totalNumber = 0
  let totalUntaxed = 0
  let groupTaxes: any = []

  const taxesWithCalc: TaxWithCalc[] = []

  for (const i in orders) {
    const order = orders[i]
    const oPrice = getResultPrice(order) // order price included only modifiers
    let rPrice = oPrice * order.quantity // order price with discounts
    let rDiscount = 0 // discount amount of each order
    let rVatExc = 0 // vat exclusive of each order
    let rVatInc = 0 // vat inclusive of each order
    let rWht = 0 // withholding tax amount of each order
    let rFee = 0 // fee amount of each order
    let rServiceCharge = 0 // service charge amount of each order
    let rUntaxed = 0 // untaxed amount of each order

    subTotalNumber += rPrice

    let tempDiscountPercent = 0 // tempDiscountPercent is not number but percentage such as 10%
    let tempDiscountNumber = 0 // tempDiscountNumber is not percentage but number such as 100 BATH

    if (includeDiscount) {
      for (const j in order.discounts) {
        const discount = order.discounts[j]
        if (discount) {
          const discountValueType = discount.value_type
          const discountValueAmount = discount.value_amount
          if (discountValueType == 'percentage') {
            tempDiscountPercent += discountValueAmount
          } else if (discountValueType == 'number') {
            tempDiscountNumber += discountValueAmount
          }
        }
      }
    }

    const discountA = tempDiscountPercent > 0 ? (oPrice - oPrice * ((100 - tempDiscountPercent) / 100)) : 0 // final discount of percent
    const discountB = tempDiscountNumber // final discount of number

    rDiscount += (discountA * order.quantity) + discountB
    rPrice -= rDiscount
    rUntaxed = rPrice
    discountNumber += rDiscount

    if (includeTax) {
      const taxes = order.taxes.map((orderTax) : ProductTax => {
        const masterTax = masterData.taxes.find((masterTax) => masterTax.id === orderTax.tax_id)
        return {
          taxId: orderTax.tax_id,
          name: orderTax.name,
          type: orderTax.type,
          formType: orderTax.form_type,
          amount: orderTax.amount,
          groupName: masterTax?.groupName || '',
          isDisplayFeeResult: !!masterTax?.isDisplayFeeResult,
          isForSale: !!masterTax?.isForSale,
          isForPurchase: !!masterTax?.isForPurchase,
          isActive: !!masterTax?.isActive,
          fee: masterTax?.fee || null,
        }
      })
      for (const k in taxes) {
        const tax = taxes[k]
        let inc = null
        let exc = null
        let taxResult = 0
        let serviceChargeResult = 0
        let feeResult = 0

        if (tax.type == 'include') {
          const {
            taxTotal,
            serviceChargeTotal,
            feeTotal,
          } = calcTax(rUntaxed, tax, groupTaxes)
          rVatInc = taxTotal
          rServiceCharge += serviceChargeTotal
          rFee += feeTotal
          rUntaxed -= rVatInc
          inc = rVatInc
          taxResult = taxTotal
          serviceChargeResult = serviceChargeTotal
          feeResult = feeTotal
          groupTaxes = addTax(groupTaxes, tax, taxTotal, serviceChargeTotal, feeTotal)
        } else if (tax.type == 'exclude') {
          if (tax.name == '{wht}') {
            const {
              taxTotal,
              serviceChargeTotal,
              feeTotal,
            } = calcTax(rUntaxed, tax, groupTaxes)
            rWht += taxTotal
            rServiceCharge += serviceChargeTotal
            rFee += feeTotal
            taxResult = taxTotal
            serviceChargeResult = serviceChargeTotal
            feeResult = feeTotal
            groupTaxes = addTax(groupTaxes, tax, taxTotal, serviceChargeTotal, feeTotal)
          } else {
            const {
              taxTotal,
              serviceChargeTotal,
              feeTotal,
            } = calcTax(rUntaxed, tax, groupTaxes)
            rVatExc += taxTotal
            rServiceCharge += serviceChargeTotal
            rFee += feeTotal
            exc = taxTotal
            taxResult = taxTotal
            serviceChargeResult = serviceChargeTotal
            feeResult = feeTotal
            groupTaxes = addTax(groupTaxes, tax, taxTotal, serviceChargeTotal, feeTotal)
          }
        }

        taxesWithCalc.push({
          ...tax,
          result: {
            exclusive: exc,
            inclusive: inc,
            taxTotal: taxResult,
            serviceChargeTotal: serviceChargeResult,
            feeTotal: feeResult,
          },
          total: 0,
          displayText: '',
        })
      }
    }

    vatExcNumber += rVatExc
    vatIncNumber += rVatInc
    whtNumber += rWht
    serviceChargeNumber += rServiceCharge
    feeNumber += rFee
    totalUntaxed += rUntaxed
  }

  totalNumber = subTotalNumber - discountNumber + vatExcNumber + whtNumber + serviceChargeNumber

  return {
    discount: round(discountNumber),
    taxes: {
      orders: taxesWithCalc,
      vat: {
        exclusive: round(vatExcNumber),
        inclusive: round(vatIncNumber),
      },
      wht: round(whtNumber),
      all: round(vatExcNumber) + vatIncNumber + whtNumber,
    },
    serviceCharge: {
      all: round(serviceChargeNumber),
    },
    fee: {
      all: round(feeNumber),
    },
    groupTaxes: round(groupTaxes),
    untaxed: round(totalUntaxed),
    sub: round(subTotalNumber),
    preTax: round(subTotalNumber - discountNumber),
    grand: round(totalNumber - whtNumber),
    payment: round(totalNumber),
  }
}

export const getTaxesByOrders = (orders: Order[], isGroupTaxName = true, masterData: MasterData) => {
  let taxes: TaxWithCalc[] = []
  for (const order of orders) {
    const total = getOrderTotal([order], masterData, true, true)
    taxes = [
      ...taxes,
      ...total.taxes.orders,
    ]
  }
  taxes = getFinalTaxes(taxes, isGroupTaxName)
  return taxes
}

const getFinalTaxes = (taxes: TaxWithCalc[], isGroupTaxName = true) => {
  const results = taxes
      // sum up total
      .reduce((acc: TaxWithCalc[], cur) => {
        const findIndex = acc.findIndex((item: TaxWithCalc) => item.taxId === cur.taxId)
        if (findIndex !== -1) {
          acc[findIndex].result.taxTotal += cur.result.taxTotal
          acc[findIndex].result.serviceChargeTotal += cur.result.serviceChargeTotal
          acc[findIndex].result.feeTotal += cur.result.feeTotal
        } else {
          acc.push(cur)
        }
        return acc
      }, [])
      .map((item) : TaxWithCalc => {
        return {
          ...item,
          name: item.name,
          total: (() => {
            if (item.formType === 'tax') {
              return item.result.taxTotal
            } else if (item.formType === 'service_charge') {
              return item.result.serviceChargeTotal
            } else if (item.formType === 'fee') {
              return item.result.feeTotal
            } else {
              return 0
            }
          })(),
        }
      })
      // filter only display result
      .filter((item) => item.isDisplayFeeResult === true)
      // group tax
      .reduce((acc: TaxWithCalc[], cur) => {
        if (cur.groupName && isGroupTaxName) {
          const findIndex = acc.findIndex((item: any) => item.groupName === cur.groupName)
          if (findIndex !== -1) {
            acc[findIndex].total += cur.total
          } else {
            acc.push({
              ...cur,
              displayText: `${cur.groupName}`,
            })
          }
        } else {
          acc.push({
            ...cur,
            displayText: getDisplayTaxNameRate(getMasterDataText(cur.name), cur.amount),
          })
        }
        return acc
      }, [])

  // sorting
  const taxesData = results.filter((v: TaxWithCalc) => v.formType === 'tax')
  const serviceChargeData = results.filter((v: TaxWithCalc) => v.formType === 'service_charge')
  const feeData = results.filter((v: TaxWithCalc) => v.formType === 'fee')

  return [
    ...serviceChargeData,
    ...taxesData,
    ...feeData,
  ]
}

export const getDisplayTaxNameRate = (taxName: string, taxRate: number) => {
  return `${taxName} ${Math.abs(taxRate)}%`
}

export const getMasterDataText = (name: string) => {
  if (['{VAT_EXC}'].includes(name)) {
    return 'VAT (รวมนอกราคา)'
  } else if (['{VAT_INC}'].includes(name)) {
    return 'VAT (รวมในราคา)'
  } else if (['{WHT_T}'].includes(name)) {
    return 'หัก ณ ที่จ่าย (ขนส่ง)'
  } else if (['{WHT_A}'].includes(name)) {
    return 'หัก ณ ที่จ่าย (โฆษณา)'
  } else if (['{WHT_S}'].includes(name)) {
    return 'หัก ณ ที่จ่าย (บริการ)'
  } else if (['{WHT_R}'].includes(name)) {
    return 'หัก ณ ที่จ่าย (เช่า)'
  } else if (['{table}'].includes(name)) {
    return 'โต๊ะ'
  } else {
    return name
  }
}

export const isCalculatedOrder = (status: OrderRef['status']) => {
  if (status === 'rejected' || status === 'deleted') {
    return false
  }
  return true
}

export const isProductAvailable = (product: Product, masterData: MasterData) => {
  if (product.prices.every((sku) => sku.isAvailable == false)) {
    return false
  }
  if (masterData.posSettings.productOutOfStock.saleDisabled === true) {
    if (product.prices.length === 1 && product.prices[0].stocks.length) {
      if (product.prices[0].stocks[0].instock <= masterData.posSettings.productOutOfStock.count) {
        return false
      }
    }
  }
  return true
}

export const getNumber = (number: any, masterData: MasterData) => {
  const originNumber = number

  if (number === undefined || number === '' || number === null) return

  if (typeof number === 'string') {
    number = parseFloat(number.replace(/,/g, ''))
  }

  if (Number.isNaN(number)) {
    return originNumber
  }
  const generalSettings = masterData.generalSettings
  const place = generalSettings.appearance.formatNumber.decimal.place
  const useComma = generalSettings.appearance.formatNumber.useComma
  if (!useComma) {
    return number.toLocaleString('en-US', {
      minimumFractionDigits: place,
      maximumFractionDigits: place,
    }).replace(',', '')
  }
  return number.toLocaleString('en-US', {
    minimumFractionDigits: place,
    maximumFractionDigits: place,
  })
}

export const getModifierHelperText = (modifier: ProductModifier) => {
  const messages = []
  if (modifier.isRequired) {
    messages.push('จำเป็นต้องเลือก')
  }
  if (modifier.minRequiredCount == modifier.maxRequiredCount && modifier.maxRequiredCount != 0) {
    messages.push(`ได้ ${modifier.minRequiredCount} อย่าง`)
  } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount == 0) {
    messages.push(`อย่างน้อย ${modifier.minRequiredCount} อย่าง`)
  } else if (modifier.minRequiredCount == 0 && modifier.maxRequiredCount != 0) {
    messages.push(`ได้ถึง ${modifier.maxRequiredCount} อย่าง`)
  } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount != 0) {
    messages.push(`ได้ ${modifier.minRequiredCount} ถึง ${modifier.maxRequiredCount} อย่าง`)
  }
  return messages.join(', ')
}

export const getModifierMaxSelected = (modifier: ProductModifier) => {
  if (modifier.minRequiredCount == modifier.maxRequiredCount && modifier.maxRequiredCount != 0) {
    return modifier.maxRequiredCount
  } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount == 0) {
    return 0
  } else if (modifier.minRequiredCount == 0 && modifier.maxRequiredCount != 0) {
    return modifier.maxRequiredCount
  } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount != 0) {
    return modifier.maxRequiredCount
  }
  return 0
}

export const getProductModifiers = (productId: string, menu: Menu[]) : ProductModifier[] => {
  for (const menuItem of menu) {
    for (const product of menuItem.products) {
      if (product.productId === productId) {
        return product.modifiers
      }
    }
  }
  return []
}

export const getModifierNamesBySort = (productId: string, orderModifiers: Order['modifiers'], menu: Menu[]) : string[] => {
  const orderOptions = orderModifiers.reduce((acc: OrderModifierOption[], cur) => {
    cur.options.forEach((opt) => {
      acc.push(opt)
    })
    return acc
  }, [])
  const productModifiers = getProductModifiers(productId, menu)
  return productModifiers.reduce((acc: string[], cur) => {
    cur.options.forEach((masterOpt) => {
      orderOptions.forEach((orderOpt) => {
        if (masterOpt.modifieroptionId === orderOpt.option_id) {
          acc.push(orderOpt.name)
        }
      })
    })
    return acc
  }, [])
}
