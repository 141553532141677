import {
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
} from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  useSelector,
} from 'react-redux'
import styled from 'styled-components'

import {
  getMasterDataText,
} from '../../helpers/utils'
import {
  Client,
} from '../../services/core.type'
import {
  RootState,
} from '../../store'
import Button, {
  BUTTON_HEIGHT,
} from '../build/Button'
import FixedButtonWrapper, {
  FIXED_BUTTON_OFFSET_BOTTOM,
} from '../build/FixedButtonWrapper'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ${BUTTON_HEIGHT + 'px'} - ${FIXED_BUTTON_OFFSET_BOTTOM * 2 + 'px'});
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  gap: 5px;
`

const Icon = styled.div`
  color: var(--app-theme-color);
  height: 52px;

  svg {
    height: 100%;
  }
`

const Message = styled.div`
  color: var(--app-theme-color);
  font-weight: bold;
`

const SubMessage = styled.div`
  color: var(--app-text-grey);
`

type PaidProps = {
  client: Client
  onDownloadReceipt: () => void
}

const Paid = (props: PaidProps) => {
  const {
    client,
    onDownloadReceipt,
  } = props

  const app = useSelector((state: RootState) => state.app)
  // eslint-disable-next-line max-len
  const subMessage = `${getMasterDataText(app.masterData?.posSettings.table.refName || '')} ${client.tableNumber} ลำดับ ${client.queueNumber}`

  return (
    <Wrapper>
      <Content>
        <Icon>
          <FontAwesomeIcon icon={ faClipboardCheck } />
        </Icon>
        <Message>
          ชำระเงินเรียบร้อยแล้ว
        </Message>
        <SubMessage>
          { subMessage }
        </SubMessage>
      </Content>

      <FixedButtonWrapper $hideButtonShadow={ true } >
        <Button
          color={ 'light' }
          onClick={ onDownloadReceipt }
        >
          ดาวน์โหลดใบเสร็จ
        </Button>
      </FixedButtonWrapper>
    </Wrapper>
  )
}

export default Paid
