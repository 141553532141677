import {
  Product,
  ProductDiscount,
  ProductDiscountCondition,
  ProductModifier,
  ProductPrice,
  ProductPriceStock,
  ProductTax,
} from '../types/product'
import {
  Auth,
  Category,
  Client,
  MasterData,
  Menu,
  QRCode,
  Response,
} from './core.type'

export const transformQrCode = (response: Response) : QRCode => {
  return {
    'appProvider': response?.data?.app_provider,
    'isClientApproveAccessRequired': response?.data?.is_client_approve_access_required,
    'isClientApproveOrderRequired': response?.data?.is_client_approve_order_required,
    'qrcodeId': response?.data?._id,
    'qrcodeRefId': response?.data?.qrcode_ref_id,
    'qrcodeType': response?.data?.qrcode_type,
    'qrcodeImage': response?.data?.qrcode_image,
    'branchId': response?.data?.branch_id,
    'branchName': response?.data?.branch_name,
    'psId': response?.data?.ps_id,
    'tableNumber': response?.data?.table_number,
    'shopId': response?.data?.shop_id,
    'createdBy': response?.data?.created_by,
    'createdAt': response?.data?.created_at,
    'expiredAt': response?.data?.expired_at,
    'expireTimeMins': response?.data?.expire_time_mins,
    'isExpired': response?.data?.is_expired,
    'isPaid': response?.data?.is_paid,
    'dateCode': response?.data?.date_code,
  }
}


export const transformAuth = (response: Response) : Auth => {
  return {
    accessToken: response?.data?.access_token,
  }
}

export const transformClient = (response: Response) : Client => {
  return {
    qrcodeId: response?.data?.qrcode_id,
    clientId: response?.data?.client_id,
    shopId: response?.data?.shop_id,
    branchId: response?.data?.branch_id,
    psId: response?.data?.ps_id,
    tableNumber: response?.data?.table_number,
    queueNumber: response?.data?.queue_number,
    expireAt: response?.data?.expire_at,
    customerId: response?.data?.customer_id,
  }
}

export const transformMenu = (response: Response) : Menu[] => {
  const categories: Category[] = response.data
      .filter((item: any) => !['1', '2'].includes(item.category_id))
      .map((item: any) => {
        return {
          categoryId: item.category_id,
          categoryName: item.category_name,
        }
      })
  categories.push({
    categoryId: null,
    categoryName: 'อื่นๆ',
  })

  const data = response.data
      .find((item: any) => item.category_id === '2')

  const menu: Menu[] = categories.map(((cateItem: Category) => {
    const products: Product[] = data.products
        .filter((productItem: any) => {
          if (cateItem.categoryId != null) {
            return productItem.category_id === cateItem.categoryId
          } else {
            return productItem.category_id == null
          }
        })
        .map((productItem: any) : Product => {
          return {
            productId: productItem.product_id,
            images: {
              thumbnail: productItem.images.thumbnail,
              original: productItem.images.original,
            },
            productName: productItem.product_name,
            prices: productItem.prices.map((sku: any) : ProductPrice => {
              return {
                skuId: sku.sku_id,
                isAvailable: !!sku.is_available,
                attributes: sku.attributes.map((attr: any) => {
                  return {
                    name: attr.name,
                    option: attr.option,
                  }
                }),
                price: sku.price,
                stocks: sku?.stocks?.map((stock: any) : ProductPriceStock => {
                  return {
                    stockId: stock.stock_id,
                    instock: stock.instock,
                  }
                }),
              }
            }),
            modifiers: productItem.modifiers.map((modi: any) : ProductModifier => {
              return {
                modifierId: modi.modifier_id,
                modifierName: modi.modifier_name,
                isRequired: modi.is_required,
                minRequiredCount: modi.min_required_count,
                maxRequiredCount: modi.max_required_count,
                options: modi.options.map((opt: any) => {
                  return {
                    modifieroptionId: opt.modifieroption_id,
                    option: opt.option,
                    price: opt.price,
                  }
                }),
              }
            }),
            discounts: productItem.discounts.map((discount: any) : ProductDiscount => {
              return {
                discountId: discount.discount_id,
                discountName: discount.discount_name,
                type: discount.type,
                valueType: discount.value_type,
                valueAmount: discount.value_amount,
                conditions: discount.conditions.map((condi: any) : ProductDiscountCondition => {
                  return {
                    conditionId: condi.condition_id,
                    condition: condi.condition,
                    quantity: condi.quantity,
                    valueType: condi.value_type,
                    valueAmount: condi.value_amount,
                  }
                }),
              }
            }),
            taxes: productItem.taxes.map((tax: any) : ProductTax => {
              return {
                taxId: tax.tax_id,
                name: tax.tax_name || tax.name,
                type: tax.type,
                amount: tax.amount,
                formType: tax.form_type,
                groupName: tax.group_name,
                isDisplayFeeResult: tax.is_display_fee_result,
                isForSale: tax.is_for_sale,
                isForPurchase: tax.is_for_purchase,
                isActive: tax.is_active,
                fee: tax.fee ? {
                  calcTaxId: tax.fee.calc_tax_id,
                  calcType: tax.fee.calc_type,
                } : null,
              }
            }),
          }
        })
        // filter custom price skus out
        .reduce((acc: Product[], cur: Product) => {
          const product = cur
          if (product.prices.length === 1 && product.prices[0].price != null) {
            acc.push(product)
          } else if (product.prices.length > 1) {
            const skus = product.prices.filter((sku) => sku.price != null)
            if (skus.length > 0) {
              acc.push({
                ...product,
                prices: skus,
              })
            } else {
              // console.log('filter out product from multiple prices', product)
            }
          } else {
            // console.log('filter out product from single price', product)
          }
          return acc
        }, [])
    return {
      ...cateItem,
      products,
    }
  }))

  return menu
}

export const transformMasterData = ({ data }: Response) : MasterData => {
  return {
    // Transform modifiers
    modifiers: data.modifiters.map((modifier: any) => ({
      id: modifier.modifier_id,
      name: modifier.modifier_name,
      options: modifier.options.map((option: any) => ({
        id: option.modifieroption_id,
        name: option.option,
        price: option.price,
      })),
      assignedProducts: modifier.assign.map((assignment: any) => assignment.product_id),
    })),

    // Transform discounts
    discounts: data.discounts.map((discount: any) => ({
      id: discount.discount_id,
      name: discount.discount_name,
      type: discount.type,
      valueType: discount.value_type,
      amount: discount.value_amount,
      conditions: discount.conditions,
      assignedProducts: discount.assign.map((assignment: any) => assignment.product_id),
    })),

    // Transform taxes
    taxes: data.taxes.map((tax: any) => ({
      id: tax.tax_id,
      name: tax.tax_name,
      type: tax.type,
      amount: tax.amount,
      isActive: tax.is_active,
      isDeletable: tax.deletable,
      assignedProducts: tax.assign.map((assignment: any) => assignment.product_id),
      formType: tax.form_type,
      groupName: tax.group_name,
      isDisplayFeeResult: tax.is_display_fee_result,
      isForPurchase: tax.is_for_purchase,
      isForSale: tax.is_for_sale,
      fee: tax.fee ? {
        calcTaxId: tax.fee.calc_tax_id,
        calcType: tax.fee.calc_type,
      } : null,
    })),

    generalSettings: {
      appearance: {
        formatNumber: {
          decimal: {
            place: data.general_settings.appearance.format_number.decimal.place,
          },
          useComma: data.general_settings.appearance.format_number.use_comma,
        },
        currency: data.general_settings.appearance.currency,
        formatDate: data.general_settings.appearance.format_date,
      },
    },

    // Directly assign pos_settings without transformation
    posSettings: {
      queue: data.pos_settings.queue.is_active,
      table: {
        isActive: data.pos_settings.table.is_active,
        count: data.pos_settings.table.count,
        refName: data.pos_settings.table.ref_name,
      },
      customer: data.pos_settings.customer.is_active,
      menuOnline: {
        isActive: data.pos_settings.menu_online.is_active,
        defaultAppProvider: data.pos_settings.menu_online.default_app_provider,
        defaultQrCodeType: data.pos_settings.menu_online.default_qrcode_type,
        isClientApproveAccessRequired: data.pos_settings.menu_online.default_is_client_approve_access_required,
        isClientApproveOrderRequired: data.pos_settings.menu_online.default_is_client_approve_order_required,
        qrCodeExpireMin: data.pos_settings.menu_online.default_qrcode_expire_min,
      },
      printSettings: {
        autoPrint: data.pos_settings.print.auto,
        paperSize: data.pos_settings.print.paper_size,
        copies: data.pos_settings.print.copies,
      },
      receiptSettings: {
        showLogo: data.pos_settings.receipt.is_show_logo,
        showName: data.pos_settings.receipt.is_show_name,
        showAddress: data.pos_settings.receipt.is_show_address,
        showTaxId: data.pos_settings.receipt.is_show_tax_id,
        showTaxTitle: data.pos_settings.receipt.is_show_tax_title,
        showTel: data.pos_settings.receipt.is_show_tel,
        showOrderNo: data.pos_settings.receipt.is_show_order_no,
        showCustomerName: data.pos_settings.receipt.is_show_customer_name,
        fontSize: data.pos_settings.receipt.font_size,
        footerText: data.pos_settings.receipt.text_footer.value,
      },
      pinSettings: data.pos_settings.pin.is_active,
      formatNumber: {
        isRound: data.pos_settings.format_number.is_round,
      },
      productOutOfStock: {
        saleDisabled: data.pos_settings.product_out_of_stock.sale_disabled,
        count: data.pos_settings.product_out_of_stock.count,
      },
      diningOptions: data.pos_settings.dining.options.map((option: any) => ({
        name: option.name,
        isActive: option.is_active,
        isDefault: option.is_default,
        deletable: option.deletable,
      })),
      paymentMethods: data.pos_settings.payment.methods.map((method: any) => ({
        name: method.name,
        isActive: method.is_active,
        isDefault: method.is_default,
        deletable: method.deletable,
      })),
    },
    notifications: data?.notifications?.map((item: any) => {
      return {
        code: item.code || [],
        channel: item.channel,
        passport: item.passport,
      }
    }),
    shop: {
      shop_id: data?.shop?.shop_id,
      user_id: data?.shop?.user_id,
    },
  }
}
