// Need to use the React-specific entry point to import createApi
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'

import {
  getLocalClient,
} from '../helpers/utils'
import {
  transformAuth,
  transformClient,
  transformMasterData,
  transformMenu,
  transformQrCode,
} from './core.transform'
import {
  Auth,
  AuthPayload,
  Client,
  MasterData,
  Menu,
  Notify,
  Preorder,
  QRCode,
} from './core.type'

const BASE_CORE_API = process.env.REACT_APP_BASE_CORE_API

// Function to handle token refresh
const refreshAccessToken = async () => {
  const localClient = getLocalClient()
  const body: AuthPayload = {
    'client_id': localClient?.clientId || '',
    'qrcode_id': localClient?.qrcodeId || '',
    'shop_id': localClient?.shopId || '',
    'branch_id': localClient?.branchId || '',
    'table_number': localClient?.tableNumber || '',
  }
  const response = await fetch(`${BASE_CORE_API}auth`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  if (!response.ok) {
    // TODO: show error to user
    throw new Error('Failed to refresh token')
  }
  const result = await response.json()
  const accessToken = result.data.access_token
  localStorage.setItem('access_token', accessToken)
  return accessToken
}

// Base query with token refresh logic
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  let result = await fetchBaseQuery({
    baseUrl: BASE_CORE_API,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem('access_token')
      if (token) {
        headers.set('Authorization', token)
      }
      return headers
    },
  })(args, api, extraOptions)

  // If we receive a 401 response, try refreshing the token
  if (result.error && result.error.status === 401) {
    try {
      const newToken = await refreshAccessToken()

      // Retry the original request with the new token
      result = await fetchBaseQuery({
        baseUrl: BASE_CORE_API,
        prepareHeaders: (headers) => {
          headers.set('Authorization', newToken)
          return headers
        },
      })(args, api, extraOptions)
    } catch (error) {
      // Token refresh failed, logout user or handle appropriately
      console.error('Failed to refresh token', error)
    }
  }

  return result
}

// Define a service using a base URL and expected endpoints
export const coreApi = createApi({
  reducerPath: 'coreApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    preorder: builder.mutation<QRCode, { body: Preorder }>({
      query: (paylad) => ({
        method: 'post',
        url: 'qrcode/preorder',
        body: paylad.body,
      }),
      transformResponse: transformQrCode,
    }),
    qrcode: builder.mutation<QRCode, { body: { qrcodeRefId: string, dateCode: string } }>({
      query: (paylad) => ({
        method: 'get',
        url: `qrcode/${paylad.body.qrcodeRefId}/${paylad.body.dateCode}`,
      }),
      transformResponse: transformQrCode,
    }),
    auth: builder.mutation<Auth, { body: AuthPayload }>({
      query: (paylad) => ({
        method: 'post',
        url: 'auth',
        body: paylad.body,
      }),
      transformResponse: transformAuth,
    }),
    client: builder.mutation<Client, void>({
      query: () => ({
        method: 'get',
        url: 'client',
      }),
      transformResponse: transformClient,
    }),
    menu: builder.mutation<Menu[], void>({
      query: () => ({
        method: 'get',
        url: 'menu',
      }),
      transformResponse: transformMenu,
    }),
    masterData: builder.mutation<MasterData, void>({
      query: () => ({
        method: 'get',
        url: 'master-data',
      }),
      transformResponse: transformMasterData,
    }),
    notify: builder.mutation<any, { body: Notify }>({
      query: (paylad) => ({
        method: 'post',
        url: 'notify',
        body: paylad.body,
      }),
    }),
  }),
})

export const {
  usePreorderMutation,
  useQrcodeMutation,
  useAuthMutation,
  useClientMutation,
  useMenuMutation,
  useMasterDataMutation,
  useNotifyMutation,
} = coreApi
