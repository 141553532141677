import {
  faQrcode,
  faShoppingBasket,
  faUtensils,
} from '@fortawesome/free-solid-svg-icons'
import {
  IonIcon,
  IonLabel,
  IonTab,
  IonTabBar as BaseIonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react'
import React, {
  useEffect,
  useState,
} from 'react'
import {
  useSelector,
} from 'react-redux'
import {
  useDispatch,
} from 'react-redux'
import styled from 'styled-components'
import {
  v4 as uuidv4,
} from 'uuid'

import {
  ERROR_MESSAGE,
} from '../../helpers/enums'
import {
  clearLocalStoreage,
  delay,
  faIcon,
  getQueryUrl,
  setLocalClientStatus,
  sortedMenus,
} from '../../helpers/utils'
import useCallRef from '../../hooks/useCallRef'
import useClientRef from '../../hooks/useClientRef'
import useCore from '../../hooks/useCore'
import useLiff, {
  LineUserProfile,
} from '../../hooks/useLiff'
import useLoading from '../../hooks/useLoading'
import useMain from '../../hooks/useMain'
import useMyOrderRef from '../../hooks/useMyOrderRef'
import useMyQueueRef from '../../hooks/useMyQueueRef'
import useNotifyRef from '../../hooks/useNotifyRef'
import useSkuRef from '../../hooks/useSkuRef'
import useStockRef from '../../hooks/useStockRef'
import {
  Client,
  QRCode,
} from '../../services/core.type'
import type {
  RootState,
} from '../../store'
import {
  setCurrentPage,
  setErrMsg,
  updateSku,
  updateStock,
} from '../../store/appSlice'
import {
  ClientStatus,
} from '../../types/client'
import {
  NotifyCode,
} from '../../types/notify'
import Error from '../assemble/Error'
import MyOrder from '../assemble/MyOrder'
import Paid from '../assemble/Paid'
import PickOrder from '../assemble/PickOrder'
import ShareQRCode from '../assemble/ShareQRCode'
import StartupScreen, {
  StartupScreenProps,
} from '../combine/StartupScreen'

type MainState = {
  isPreInitMenuDone: boolean
  isInitMenuDone: boolean
  lineUserProfile?: LineUserProfile
  qrcodeRefId: string
  dateCode: string
  loadingType: StartupScreenProps['type']
}

export const IonTabBar = styled(BaseIonTabBar)`
  height: 65px;

  ion-tab-button {
    background-color: #fff;
    color: #C8C8C8;
    font-size: 12px;

    --color-selected: var(--app-theme-color);
  }

  ion-tab-button .ion-ripple-effect {
    display: none;
  }

  ion-tab-button.tab-selected {
    color: var(--app-theme-color);
  }
`

const Main = () => {
  /**
   * state, hooks
   */
  const [mainState, setMainState] = useState<MainState>({
    isPreInitMenuDone: false,
    isInitMenuDone: false,
    lineUserProfile: undefined,
    qrcodeRefId: '',
    dateCode: '',
    loadingType: 'skeleton',
  })
  const app = useSelector((state: RootState) => state.app)
  const liff = useLiff()
  const dispatch = useDispatch()
  const {
    progress,
    start,
    finish,
  } = useLoading()
  const main = useMain()
  const {
    getPreOrder,
    getQRCode,
    getAuth,
    getClient,
    getMenu,
    getMasterData,
    qrcodeResult,
    clientResult,
    isExpired,
  } = useCore()
  const {
    orders: myOrdersRef,
    subscribe: subscribeMyOrder,
  } = useMyOrderRef()
  const {
    client: clientRef,
    subscribe: subscribeClient,
    create: createClientRef,
    waitForRequestAccessApproved,
  } = useClientRef()
  const { create: createNotifyRef } = useNotifyRef()
  const {
    isCallCheckout,
    subscribe: subscribeCall,
  } = useCallRef()
  const {
    subscribe: subscribeQueueRef,
    isPaid,
  } = useMyQueueRef()
  const {
    skus: skusRef,
    subscribe: subscribeSkuRef,
  } = useSkuRef()
  const {
    stocks: stocksRef,
    subscribe: subscribeStockRef,
  } = useStockRef()

  /**
   * local functions
   */
  const preInitialMenu = (
      callback: (error: string | null, qrcodeRefId?: string, dateCode?: string, lineUserProfile?: LineUserProfile) => void,
  ) => {
    if (liff.isInClient()) {
      liff.init(async (errMsg, profile) => {
        if (errMsg) {
          callback(errMsg)
        } else {
          const lineUserProfile = profile as LineUserProfile
          const {
            qrcodeRefId,
            dateCode,
          } = getQueryUrl(window.location.pathname)
          const isPreorder = qrcodeRefId === 'preorder'
          if (isPreorder) {
            const shopId = dateCode
            const preorder = await getPreOrder(lineUserProfile, shopId)
            callback(null, preorder.qrcodeRefId, preorder.dateCode, lineUserProfile)
          } else {
            callback(null, qrcodeRefId, dateCode, lineUserProfile)
          }
        }
      })
    } else {
      const {
        qrcodeRefId,
        dateCode,
      } = getQueryUrl(window.location.pathname)
      callback(null, qrcodeRefId, dateCode)
    }
  }

  const initialMenu = async (
      options: {
        qrcodeRefId: string,
        dateCode: string,
        lineUserProfile?: LineUserProfile,
      },
      callback: (error: string | null) => void) => {
    try {
      const {
        qrcodeRefId,
        dateCode,
        lineUserProfile,
      } = options

      clearLocalStoreage()

      const qrcode = await getQRCode(qrcodeRefId, dateCode)

      if (qrcode.qrcodeType === 'one_time_use' && qrcode.isExpired || qrcode.isPaid) {
        return callback('คิวอาร์โค้ดหมดอายุแล้ว')
      }

      if (qrcode.appProvider === 'web') {
        if (liff.isInClient()) {
          return callback('ไม่สามารถใช้งานผ่านไลน์ กรุณาเปิดใช้งานผ่านเว็บ')
        }
      } else if (qrcode.appProvider === 'line') {
        if (!liff.isInClient()) {
          return callback('ไม่สามารถใช้งานผ่านเว็บ กรุณาเปิดใช้งานผ่านไลน์')
        }
      }

      const clientId = uuidv4()
      const clientStatus: ClientStatus = qrcode.isClientApproveAccessRequired ? 'waiting_for_approve' : 'approved'
      await createClientRef(qrcode.shopId, qrcode.branchId, qrcode.psId, clientId, clientStatus, qrcode.qrcodeId)
      await subscribeClient(qrcode.shopId, qrcode.branchId, qrcode.psId, clientId)

      if (qrcode.isClientApproveAccessRequired) {
        const queueNumber = 'unknown' // the client will get queue number right after authentication, so keep it as unknown
        const code: NotifyCode = 'request_approve_access'
        const metadata = {
          client_id: clientId,
        }
        await createNotifyRef(qrcode, queueNumber, clientId, code, metadata)
        await waitForRequestAccessApproved()
      }

      await getAuth(clientId, qrcode.qrcodeId, qrcode.shopId, qrcode.branchId, qrcode.tableNumber, lineUserProfile) as string

      const client = await getClient()
      await getMenu()
      await getMasterData()
      await subscribeMyOrder(client)
      await subscribeQueueRef(client)
      await subscribeCall(client)
      await subscribeSkuRef(client)
      await subscribeStockRef(client)

      document.title = qrcode.branchName

      callback(null)
    } catch (error) {
      if (error === ERROR_MESSAGE.NOT_FOUND_POS_SESSION) {
        callback('ขออภัย ร้านค้าไม่ได้ "เปิดการขาย" ในขณะนี้')
      } else {
        callback('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
      }
    }
  }

  const finishWithError = (errMsg: string) => {
    dispatch(setCurrentPage('ERROR'))
    dispatch(setErrMsg(errMsg))
    finish()
  }

  /**
   * listener
   */

  // pre init menu
  useEffect(() => {
    preInitialMenu((errMsg, qrcodeRefId = '', dateCode = '', lineUserProfile) => {
      if (errMsg) {
        finishWithError(errMsg)
      } else {
        setMainState({
          ...mainState,
          isPreInitMenuDone: true,
          qrcodeRefId,
          dateCode,
          lineUserProfile,
          loadingType: 'skeleton',
        })
      }
    })
  }, [])

  // init menu
  useEffect(() => {
    if (mainState.isPreInitMenuDone && !mainState.isInitMenuDone) {
      start()

      const options = {
        qrcodeRefId: mainState.qrcodeRefId,
        dateCode: mainState.dateCode,
        lineUserProfile: mainState.lineUserProfile,
      }
      initialMenu(options, (errMsg) => {
        setMainState({
          ...mainState,
          isInitMenuDone: true,
        })

        if (errMsg) {
          finishWithError(errMsg)
        } else {
          finish()
        }
      })
    }
  }, [mainState])

  useEffect(() => {
    setLocalClientStatus(clientRef?.clientStatus)
  }, [clientRef])

  useEffect(() => {
    if (isCallCheckout && app.currentPage !== 'MY_ORDERS') {
      dispatch(setCurrentPage('MY_ORDERS'))
    }
  }, [isCallCheckout])

  useEffect(() => {
    if (isPaid) {
      dispatch(setCurrentPage('PAID'))
    }
  }, [isPaid])

  useEffect(() => {
    stocksRef.forEach((stock) => {
      dispatch(updateStock(stock))
    })
  }, [stocksRef])

  useEffect(() => {
    skusRef.forEach((sku) => {
      dispatch(updateSku(sku))
    })
  }, [skusRef])

  const isLoading = progress < 101
  if (isLoading) {
    return (
      <StartupScreen
        value={ progress }
        type={ mainState.loadingType }
      />
    )
  }

  if (app.currentPage === 'ERROR') {
    return (
      <div>
        <Error message={ app.errMsg || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง' } />
      </div>
    )
  }

  if (isExpired) {
    return (
      <div>
        <Error message={ 'คิวอาร์โค้ดหมดอายุแล้ว' } />
      </div>
    )
  }

  if (app.currentPage === 'PAID') {
    return (
      <Paid
        client={ clientResult.data as Client }
        onDownloadReceipt={ () => {
          const url = (() => {
            let path = `qrcode/${qrcodeResult.data?.qrcodeRefId}/${qrcodeResult.data?.dateCode}`
            path += `/ใบเสร็จรับเงิน_${qrcodeResult.data?.branchName}_${qrcodeResult.data?.dateCode}?lang=th`
            return `${process.env.REACT_APP_BASE_CORE_API}${path}`
          })()
          if (liff.isInClient()) {
            liff.openWindow({
              external: true,
              url,
            })
          } else {
            window.open(url, '_blank')
          }
        } }
      />
    )
  }

  const renderContent = () => {
    if (app.currentPage === 'PICK_ORDERS') {
      return (
        <PickOrder
          client={ (clientResult.data || []) as Client }
          menus={ sortedMenus(app.menus) }
          onConfirmOrders={ async (orders, callback) => {
            if (orders.length) {
              await main.createOrders(
                  orders,
                  clientResult.data as Client,
                  qrcodeResult.data as QRCode,
              )
              const isFirstTime = myOrdersRef.length === 0
              if (isFirstTime) {
                await delay(3000)
              } else {
                await delay(1000)
              }
              const error = null
              const alertMessage = isFirstTime ? 'ท่านสามารถสั่งออเดอร์เพิ่ม หรือกดปุ่ม "ส่งคำขอเช็คบิล" ได้ในภายหลัง' : null
              callback(error, alertMessage)
            }
          } }
        />
      )
    } else if (app.currentPage === 'MY_ORDERS') {
      return (
        <MyOrder
          client={ (clientResult.data || []) as Client }
          orders={ myOrdersRef }
          isCheckout={ isCallCheckout }
          isClientApproveOrderRequired={ true }
          onCheckout={ async (billTotal, callback) => {
            main.checkout(
              clientResult.data as Client,
              qrcodeResult.data as QRCode,
              billTotal,
            )
            await delay(3000)
            const error = null
            callback(error)
          } }
          onCancelCheckout={ () => {
            main.CancelCheckout(
              clientResult.data as Client,
              qrcodeResult.data as QRCode,
            )
          } }
        />
      )
    } else if (app.currentPage === 'SHARE_MY_QRCODE') {
      return (
        <ShareQRCode
          client={ (clientResult.data || []) as Client }
          qrcode={ qrcodeResult.data as QRCode }
        />
      )
    }
  }

  return (
    <IonTabs>
      { app.currentPage }
      <IonTab tab={ 'PICK_ORDERS' }>
        { renderContent() }
      </IonTab>
      <IonTab tab={ 'MY_ORDERS' }>
        { renderContent() }
      </IonTab>
      <IonTab tab={ 'SHARE_MY_QRCODE' }>
        { renderContent() }
      </IonTab>

      <IonTabBar slot="bottom">
        <IonTabButton
          tab={ 'PICK_ORDERS' }
          selected={ app.currentPage === 'PICK_ORDERS' }
          disabled={ isCallCheckout }
          onClick={ (e) => {
            dispatch(setCurrentPage('PICK_ORDERS'))
          } }
        >
          <IonIcon
            icon={ faIcon(faUtensils) }
            style={ {
              width: '17px',
            } }
          />
          <IonLabel>
            เลือกเมนูอาหาร
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab={ 'MY_ORDERS' }
          selected={ app.currentPage === 'MY_ORDERS' }
          onClick={ (e) => {
            dispatch(setCurrentPage('MY_ORDERS'))
          } }
        >
          <IonIcon
            icon={ faIcon(faShoppingBasket) }
            style={ {
              width: '24px',
            } }
          />
          <IonLabel>
            ออเดอร์ของฉัน (
            { myOrdersRef.length }
            )
          </IonLabel>
        </IonTabButton>
        <IonTabButton
          tab={ 'SHARE_MY_QRCODE' }
          selected={ app.currentPage === 'SHARE_MY_QRCODE' }
          disabled={ isCallCheckout }
          onClick={ (e) => {
            dispatch(setCurrentPage('SHARE_MY_QRCODE'))
          } }
        >
          <IonIcon
            icon={ faIcon(faQrcode) }
            style={ {
              width: '18px',
            } }
          />
          <IonLabel>
            แชร์คิวอาร์โค้ด
          </IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  )
}

export default Main
