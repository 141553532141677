import {
  IonCheckbox,
} from '@ionic/react'
import React from 'react'
import styled from 'styled-components'

import {
  ProductModifierOption,
} from '../../types/product'

const Wrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  opacity: ${(props) => props.disabled ? '.5' : '1'};

  &:last-of-type {
    margin-bottom: 0;
  }
`

const RadioWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const OptionName = styled.div`

`

const OptionPrice = styled.div`

`

type ModifierProps = {
  modifierOption: ProductModifierOption
  isActive: boolean
  disabled: boolean
  onClick: (id: string, disabled: boolean) => void
}

const Modifier = (props: ModifierProps) => {
  const {
    modifierOption,
    isActive = false,
    disabled,
    onClick,
  } = props

  const price = modifierOption.price

  return (
    <Wrapper
      disabled={ disabled }
      onClick={ () => onClick(modifierOption.modifieroptionId, disabled) }
    >
      <RadioWrapper>
        <IonCheckbox
          checked={ isActive }
          disabled={ disabled }
        />
        <OptionName>
          { modifierOption.option }
        </OptionName>
      </RadioWrapper>
      <OptionPrice>
        { price > 0 && '+' }
        { price }
      </OptionPrice>
    </Wrapper>
  )
}

export default Modifier
