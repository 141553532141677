import {
  IonContent,
  IonFooter,
} from '@ionic/react'
import React, {
  useEffect,
  useState,
} from 'react'
import styled from 'styled-components'

import {
  getModifierMaxSelected,
} from '../../helpers/utils'
import {
  Order,
  OrderAttribute,
  OrderDiscount,
  OrderModifier,
  OrderTax,
} from '../../types/order'
import {
  Product,
  ProductDiscount,
  ProductDiscountCondition,
  ProductModifier,
  ProductModifierOption,
} from '../../types/product'
import Button from '../build/Button'
import SetUnitButton from '../build/SetUnitButton'
import Toolbar from '../build/Toolbar'
import CoverImage from '../combine/CoverImage'
import ProductForm from './ProductForm'

const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
`

export type SelectProductForm = {
  productId: string
  skuId: string
  item: string
  price: number,
  attributes: OrderAttribute[]
  discounts: OrderDiscount[]
  modifiers: OrderModifier[]
  taxes: OrderTax[]
  note?: string | null
  quantity: number
  uuid?: Order['uuid']
}

type SelectProductModalProps = {
  formType?: 'create' | 'edit'
  product: Product
  defaultValues?: SelectProductForm
  onClose?: () => void
  onSubmit?: (form: SelectProductForm) => void
  onDelete?: (orderUuid: Order['uuid']) => void
  onEdit?: (form: SelectProductForm) => void
}

const SelectProductModal = (props: SelectProductModalProps) => {
  const {
    formType = 'create',
    product,
    defaultValues,
    onClose,
    onSubmit,
    onDelete,
    onEdit,
  } = props

  const initFormState: SelectProductForm = {
    productId: product.productId,
    skuId: defaultValues?.skuId ? defaultValues.skuId : '',
    item: defaultValues?.item ? defaultValues.item : '',
    price: defaultValues?.price ? defaultValues.price : 0,
    attributes: defaultValues?.attributes ? defaultValues.attributes : [],
    discounts: defaultValues?.discounts ? defaultValues.discounts : product.discounts
        .filter((discount) => discount.type === 'general')
        .map((discount) => ({
          discount_id: discount.discountId,
          value_type: discount.valueType,
          value_amount: discount.valueAmount,
        })),
    modifiers: defaultValues?.modifiers ? defaultValues.modifiers : [],
    taxes: defaultValues?.taxes ? defaultValues.taxes : product.taxes
        .filter((tax) => tax.isForSale && tax.isActive)
        .map((tax) => ({
          tax_id: tax.taxId,
          name: tax.name,
          type: tax.type,
          form_type: tax.formType,
          amount: tax.amount,
        })),
    note: defaultValues?.note ? defaultValues.note : '',
    quantity: defaultValues?.quantity ? defaultValues.quantity : 1,
    uuid: defaultValues?.uuid ? defaultValues.uuid : undefined,
  }

  const [form, setForm] = useState<SelectProductForm>(initFormState)
  const [isValidForm, setIsValidForm] = useState(false)

  const checkBoxModifierOptionIds = form.modifiers.reduce((acc: string[], cur) => {
    const optionIds = cur.options.map((opt) => opt.option_id)
    if (optionIds.length) {
      acc = [
        ...acc,
        ...optionIds,
      ]
    }
    return acc
  }, [])

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(form)
    }
  }

  const handleSkuChange = (skuId: string, productFullName: string) => {
    const sku = product.prices.find((price) => price.skuId === skuId)
    const attributes = sku?.attributes || []
    setForm((prev) => ({
      ...prev,
      skuId: skuId,
      item: productFullName,
      price: sku?.price || 0,
      attributes,
    }))
  }

  const getModifierIdBy = (optionId: string, modifiers: ProductModifier[]) => {
    for (let i = 0; i < modifiers.length; i++) {
      for (let j = 0; j < modifiers[i].options.length; j++) {
        if (modifiers[i].options[j].modifieroptionId === optionId) {
          return modifiers[i].modifierId
        }
      }
    }
    return ''
  }

  const getModifierOptionBy = (optionId: string, modifiers: ProductModifier[]) : ProductModifierOption | null => {
    for (let i = 0; i < modifiers.length; i++) {
      for (let j = 0; j < modifiers[i].options.length; j++) {
        if (modifiers[i].options[j].modifieroptionId === optionId) {
          return modifiers[i].options[j]
        }
      }
    }
    return null
  }

  const handleModifiersChange = (optionIds: string[]) => {
    const modifiers = optionIds.reduce((acc: OrderModifier[], cur) => {
      const optionId = cur
      const modifierId = getModifierIdBy(optionId, product.modifiers)
      const option = getModifierOptionBy(optionId, product.modifiers)
      const foundAtIndex = acc.findIndex((modiItem) => modiItem.modifier_id === modifierId)
      if (foundAtIndex >= 0) {
        acc[foundAtIndex].options.push({
          option_id: optionId,
          name: option?.option || '',
          price: option?.price || 0,
        })
      } else {
        acc.push({
          modifier_id: modifierId,
          options: [
            {
              option_id: optionId,
              name: option?.option || '',
              price: option?.price || 0,
            },
          ],
        })
      }
      return acc
    }, [])
    setForm((prev) => ({
      ...prev,
      modifiers,
    }))
  }

  const handleNoteChange = (note: string) => {
    setForm((prev) => ({
      ...prev,
      note: note,
    }))
  }

  const handleQuantityChange = (value: number) => {
    setForm((prev) => ({
      ...prev,
      quantity: value,
    }))
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(initFormState.uuid)
    }
  }

  const handleEdit = () => {
    if (onEdit) {
      onEdit(form)
    }
  }

  useEffect(() => {
    setForm(initFormState)
  }, [])

  useEffect(() => {
    const meetConditionDiscounts: OrderDiscount[] = []
    const conditionalDiscounts = product.discounts.filter((discount) => discount.type === 'conditional')
    conditionalDiscounts.forEach((discount) => {
      let result: ProductDiscountCondition | undefined
      discount.conditions.forEach((cond) => {
        if (cond.condition === '>=') {
          if (form.quantity >= cond.quantity) {
            result = cond
          }
        }
        if (cond.condition === '=') {
          if (form.quantity === cond.quantity) {
            result = cond
          }
        }
        if (cond.condition === '<=') {
          if (form.quantity <= cond.quantity) {
            result = cond
          }
        }
      })
      if (result != null) {
        meetConditionDiscounts.push({
          condition_id: result.conditionId,
          discount_id: discount.discountId,
          value_type: result.valueType,
          value_amount: result.valueAmount,
        })
      }
      setForm((prev) => ({
        ...prev,
        discounts: [
          ...initFormState.discounts,
          ...meetConditionDiscounts,
        ],
      }))
    })
  }, [form.quantity])

  const mapProductModifiers = product.modifiers.map((modifier) => {
    let selectedCount = 0
    const optionIds = modifier.options.map((opt) => opt.modifieroptionId)
    for (const optionId of optionIds) {
      if (checkBoxModifierOptionIds.includes(optionId)) {
        selectedCount++
      }
    }
    return {
      ...modifier,
      options: modifier.options.map((opt) => {
        const isOptionSelected = checkBoxModifierOptionIds.includes(opt.modifieroptionId)
        return {
          ...opt,
          isSelected: isOptionSelected,
          disabled: (() => {
            const maxRequiredCount = getModifierMaxSelected(modifier)
            const checkDisabled = () => {
              if (maxRequiredCount != 0) {
                if (selectedCount >= maxRequiredCount && !isOptionSelected) {
                  return true
                }
              }
            }
            if (modifier.minRequiredCount == modifier.maxRequiredCount && modifier.maxRequiredCount != 0) {
              if (checkDisabled()) {
                return true
              }
            } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount == 0) {
              //
            } else if (modifier.minRequiredCount == 0 && modifier.maxRequiredCount != 0) {
              if (checkDisabled()) {
                return true
              }
            } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount != 0) {
              if (checkDisabled()) {
                return true
              }
            }
            return false
          })(),
        }
      }),
    }
  })

  useEffect(() => {
    let isValid = true
    if (!form.skuId) {
      isValid = false
    }

    // validate modifiers
    let isValidModifiers = true
    for (const modifier of mapProductModifiers) {
      const addInvalidModifierId = () => {
        isValidModifiers = false
      }
      if (modifier.isRequired) {
        let selectedCount = 0
        const optionIds = modifier.options.map((opt) => opt.modifieroptionId)
        for (const optionId of optionIds) {
          if (checkBoxModifierOptionIds.includes(optionId)) {
            selectedCount++
          }
        }
        if (modifier.minRequiredCount == modifier.maxRequiredCount && modifier.maxRequiredCount != 0) {
          if (selectedCount != modifier.minRequiredCount) {
            addInvalidModifierId()
          }
        } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount == 0) {
          if (selectedCount < modifier.minRequiredCount) {
            addInvalidModifierId()
          }
        } else if (modifier.minRequiredCount == 0 && modifier.maxRequiredCount != 0) {
          // do not things
        } else if (modifier.minRequiredCount != 0 && modifier.maxRequiredCount != 0) {
          if (selectedCount < modifier.minRequiredCount) {
            addInvalidModifierId()
          }
        }
      }
    }

    setIsValidForm(isValid && isValidModifiers)
  }, [form])

  const renderLeftButton = () => {
    if (formType === 'create') {
      return (
        <Button
          color={ 'light' }
          onClick={ onClose }
          style={ {
            flex: 1,
            padding: 0,
          } }
        >
          ยกเลิก
        </Button>
      )
    } else {
      return (
        <Button
          color={ 'danger' }
          onClick={ handleDelete }
          style={ {
            flex: 1,
            padding: 0,
          } }
        >
          ลบ
        </Button>
      )
    }
  }

  const renderRightButton = () => {
    if (formType === 'create') {
      return (
        <Button
          color={ 'success' }
          onClick={ handleSubmit }
          style={ {
            flex: 1,
            padding: 0,
          } }
          disabled={ !isValidForm }
        >
          เพิ่ม
        </Button>
      )
    } else {
      return (
        <Button
          color={ 'success' }
          onClick={ handleEdit }
          style={ {
            flex: 1,
            padding: 0,
          } }
          disabled={ !isValidForm }
        >
          แก้ไข
        </Button>
      )
    }
  }

  const hasImage = product.images.original
  const usedDiscounts = form.discounts.map((orderDiscount) : ProductDiscount => {
    const productDiscount = (
      product.discounts.find((productDiscount) => productDiscount.discountId === orderDiscount.discount_id)
    ) as ProductDiscount
    return {
      ...productDiscount,
      valueType: orderDiscount.value_type,
      valueAmount: orderDiscount.value_amount,
      conditionId: orderDiscount.condition_id,
    }
  })

  return (
    <>
      <IonContent fullscreen={ true }>
        { hasImage &&
        <CoverImage url={ product.images.original || '' } />
        }
        <ProductForm
          product={ product }
          productModifiers={ mapProductModifiers }
          usedDiscounts={ usedDiscounts }
          defaultValues={ defaultValues }
          onSkuChange={ handleSkuChange }
          onModifiersChange={ handleModifiersChange }
          onNoteChange={ handleNoteChange }
        />
      </IonContent>
      <IonFooter>
        <Toolbar>
          <ActionButtonWrapper>
            { renderLeftButton() }
            <SetUnitButton
              style={ {
                width: '150px',
              } }
              defaultValue={ defaultValues?.quantity ? defaultValues.quantity : 1 }
              onChange={ handleQuantityChange }
            />
            { renderRightButton() }
          </ActionButtonWrapper>
        </Toolbar>
      </IonFooter>
    </>
  )
}

export default SelectProductModal
