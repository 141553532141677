import {
  useSelector,
} from 'react-redux'

import {
  getMasterDataText,
  getModifierNamesBySort,
} from '../helpers/utils'
import {
  useNotifyMutation,
} from '../services/core.api'
import {
  Client,
  MasterData,
  Menu,
  Notify,
} from '../services/core.type'
import {
  RootState,
} from '../store'
import {
  Order,
} from '../types/order'

const useNotifyChannel = () => {
  const [notifyChannelMutation] = useNotifyMutation()
  const app = useSelector((state: RootState) => state.app)
  const masterData = app.masterData as MasterData

  const getPayloadNotify = (code: Notify['code']) : Notify => {
    return {
      code: code,
      notifications: masterData.notifications
          .filter((item) => [...item.code].includes(code))
          .map((item) => {
            return {
              channel: item.channel,
              passport: item.passport,
            }
          }),
      shop_id: masterData.shop.shop_id || '',
      user_id: masterData.shop.user_id || '',
    }
  }

  const hasNotify = (code: Notify['code']) : boolean => {
    return masterData.notifications.reduce((acc: boolean, cur) => {
      cur.code.forEach((codeName) => {
        if (codeName === code) {
          acc = true
        }
      })
      return acc
    }, false)
  }

  const sendNotifyPlaceNewOrder = (code: Notify['code'], client: Client, orders: Order[]) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (hasNotify(code)) {
          await notifyChannelMutation({
            body: {
              ...getPayloadNotify(code),
              metadata: {
                'table_ref_name': getMasterDataText(masterData.posSettings.table.refName),
                'customer_name': null,
                'order_number': client.queueNumber,
                'table_number': client.tableNumber,
                'orders': orders.map((order) => {
                  return {
                    'item': order.item,
                    'quantity': order.quantity,
                    'modifiers': getModifierNamesBySort(order.product_id, order.modifiers, app.menus as Menu[]),
                    'notes': order.note,
                  }
                }),
              },
            },
          })
          resolve(true)
        } else {
          resolve(true)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  const sendNotifyCallCheckout = (code: Notify['code'], client: Client, billTotal: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (hasNotify(code)) {
          await notifyChannelMutation({
            body: {
              ...getPayloadNotify(code),
              metadata: {
                'table_ref_name': getMasterDataText(masterData.posSettings.table.refName),
                'customer_name': null,
                'order_number': client.queueNumber,
                'table_number': client.tableNumber,
                'bill_total': billTotal,
              },
            },
          })
          resolve(true)
        } else {
          resolve(true)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  return {
    sendNotifyPlaceNewOrder,
    sendNotifyCallCheckout,
  }
}

export default useNotifyChannel
