/* eslint-disable no-multi-spaces */
export const FIREBASE_CALL_REF            = 'pos/shop_id-%s/branch_id-%s/ps_id-%s/call'
export const FIREBASE_CLIENT_REF          = 'pos/shop_id-%s/branch_id-%s/ps_id-%s/client'
export const FIREBASE_NOTIFY_REF          = 'pos/shop_id-%s/branch_id-%s/ps_id-%s/notify'
export const FIREBASE_ORDER_REF           = 'pos/shop_id-%s/branch_id-%s/ps_id-%s/order'
export const FIREBASE_QUEUE_REF           = 'pos/shop_id-%s/branch_id-%s/ps_id-%s/queue'
export const FIREBASE_SKU_REF             = 'pos/shop_id-%s/sku'
export const FIREBASE_STOCK_REF           = 'pos/shop_id-%s/stock'
export const FIREBASE_ACCESS_HISTORY_REF  = 'pos/access_history'

export const ERROR_MESSAGE = Object.freeze({
  NOT_FOUND_POS_SESSION: 'not found available pos session',
})

export const NOTIFY_CHANNEL = Object.freeze({
  'REQUEST_ACCESS': '120', // Menu Online - Request Access
  'REQUEST_ORDER': '130', // Menu Online - Request Orders
  'NEW_ORDER_CREATED': '140', // Menu Online - New Orders Created
  'NEW_QUEUE_CREATED': '150', // Menu Online - New Queue Created
  'CALL_STAFF': '160', // Menu Online - Call Staff
  'CALL_CHECKOUT': '170', // Menu Online - Checkout
})
